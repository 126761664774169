var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-container"},[_c('div',{staticClass:"nav-menu"},[_c('el-menu',{staticClass:"el-menu-vertical-demo",attrs:{"default-active":"/uc/home"},on:{"select":_vm.handleSelect}},[_c('el-menu-item',{attrs:{"index":"/uc/home"}},[_c('i',{staticClass:"el-icon-s-home"}),_c('span',[_vm._v("首页")])]),_c('el-submenu',{attrs:{"index":"2"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-user"}),_c('span',[_vm._v("账户管理")])]),_c('el-menu-item',{attrs:{"index":"/uc/authenticatedList"}},[_vm._v("用户认证")]),_c('el-menu-item',{attrs:{"index":"/uc/safe"}},[_vm._v("修改密码")])],2),_c('el-submenu',{attrs:{"index":"3"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-coin"}),_c('span',[_vm._v("保证金")])]),_c('el-menu-item',{attrs:{"index":"/uc/marginManage"}},[_vm._v("保证金管理")])],2),(
          _vm.userApproveInfo.isAuthentication === '2' ||
          _vm.userApproveInfo.isAuthentication === '3'
        )?_c('el-submenu',{attrs:{"index":"4"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-document"}),_c('span',[_vm._v("我的销售")])]),_c('el-menu-item',{attrs:{"index":"/uc/releaseGoods"}},[_vm._v("商品管理")]),_c('el-menu-item',{attrs:{"index":"/uc/salesOrder"}},[_vm._v("超市订单")]),_c('el-menu-item',{attrs:{"index":"/uc/auctionSaleFair"}},[_vm._v("我的交易会")]),_c('el-menu-item',{attrs:{"index":"/uc/auctionApply"}},[_vm._v("交易会报名")]),_c('el-menu-item',{attrs:{"index":"/uc/myAuctionApply"}},[_vm._v("竞价报名商品")]),_c('el-menu-item',{attrs:{"index":"/uc/auctionSaleOrder"}},[_vm._v("竞价订单")]),_c('el-menu-item',{attrs:{"index":"/uc/basicTradeSeller"}},[_vm._v("点价意向")])],2):_vm._e(),(
          _vm.userApproveInfo.isAuthentication === '1' ||
          _vm.userApproveInfo.isAuthentication === '3'
        )?_c('el-submenu',{attrs:{"index":"5"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-shopping-cart-2"}),_c('span',[_vm._v("我的采购")])]),_c('el-menu-item',{attrs:{"index":"/uc/myPurchaseList"}},[_vm._v("超市订单")]),_c('el-menu-item',{attrs:{"index":"/uc/auctionPurchaseOrder"}},[_vm._v("竞价订单")]),_c('el-menu-item',{attrs:{"index":"/uc/basicTradeBuyer"}},[_vm._v("点价记录")])],2):_vm._e(),_c('el-submenu',{attrs:{"index":"6"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-setting"}),_c('span',[_vm._v("认证申报")])]),_c('el-menu-item',{attrs:{"index":"/uc/apply?id=5"}},[_vm._v("CCIA认证")]),_c('el-menu-item',{attrs:{"index":"/uc/apply?id=13"}},[_vm._v("本地认证")]),_c('el-menu-item',{attrs:{"index":"/uc/authentication"}},[_vm._v("其他认证")]),_c('el-menu-item',{attrs:{"index":"/uc/history"}},[_vm._v("申请记录")])],2)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }